.home-page header {
    background-image: url(../utils/img/header-img.jpg);
}

header {
    background-size: cover;
    background-position: center;
}

header h1 {
    font-size: 5rem;
}

.home-page header h1,
.home-page header h2 {
    text-shadow: 2px 2px 8px #ffffff;
}

.menu-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../utils/img/menu-img.jpg);
    background-size: cover;
    background-position: center;
}

img {
    border-style: solid;
    border-width: 5px;
}

@media only screen and (max-width: 768px) {
    header h1 {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 576px) {
    header h1 {
        font-size: 3rem;
    }
}